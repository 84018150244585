
import React, { Component }  from 'react';

import ImgGenerator from "../components/ImgGenerator";

// Translations Module
import LanguageSelector from '../components/languageSelector';
import { withTranslation } from 'react-i18next';



function checkout() {
    return (
        <>
            <LanguageSelector/>
            <ImgGenerator  />
        </>
    );
};

export default withTranslation()(checkout)