import React from 'react';
import { wrapGrid } from 'animate-css-grid'

import Carousel from 'react-bootstrap/Carousel';
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';

import Product from './product';
import AltarCard from './card'
import CardList from './productList';
import ResponsiveSlider from './slider';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';


// Translations
import i18n, { t } from 'i18next';
import { withTranslation } from 'react-i18next'

class Buy extends React.Component {

    constructor(props) {
        super(props)
        this.handleLeft = this.handleLeft.bind(this)
        this.handleRight = this.handleRight.bind(this)
        this.handleToCheckout = this.handleToCheckout.bind(this)
        this.hideAndShowText = this.hideAndShowText.bind(this)

        this.state = {
            baseProducts: [],
            products: [], // list of products
            expansions: [],
            modalsExp: [],
            showExpansions: false,
            selectedProduct: null, // selectedProduct in state
            selectedExpansion: null,
            currentPdtPosition: 0,
            currentExpPosition: 0,
            bought: [], // list of bought items
            cart: [],
            touchStartX: null,
            touchEndX: null,
            isOpen: false, // show more button on mobile
            modalShow: false,
            discounts: [],
            database: null
        };
        this.getDiscounts();
    }

    componentDidMount = () => {
        this.fetchTranslatedProducts()
        i18n.on('languageChanged', this.fetchTranslatedProducts)
    }

    componentDidUpdate(prevProps, prevState) {
        // logic here
    }

    componentWillUnmount = () => {
        i18n.off('languageChanged', this.fetchTranslatedProducts)
    }

    fetchTranslatedProducts = () => {
        const products_t = t('pulsesInfos', { ns: 'cards', returnObjects: true });
        var lng = localStorage.getItem('i18nextLng')
        fetch('/api.php', {
            method: 'POST',
            body: JSON.stringify({
                action: 'getAvailableProduct',
                lang: lng
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Server gave Error:' + response.status);
                } else {
                    return response.json()
                }
            })
            .then((data) => {
                if (data) {
                    // console.log(data)
                    if (data.AvailableProduct) {
                        // console.log('fetch data: ', data.AvailableProduct)

                        // filtering all products to base decks
                        const productsData = data.AvailableProduct.filter(p => p.ename === 'Base')

                        const productsValues = this.formatProducts(productsData)
                        this.setState({ fetchedProducts: productsValues })

                        // drawing base products selection component
                        this.setProducts(productsData, 'products', true)

                        // filtering all products to base expansions
                        const expansionsData = data.AvailableProduct.filter(e => e.ename !== 'Base')

                        if (expansionsData.length > 0) {
                            const expansionValues = this.formatProducts(expansionsData)
                            this.setState({ expansions: expansionsData })
                        }

                        // test dummy expansions
                        // const expansionsDummy = [
                        //     {
                        //         id : 7,
                        //         image : 'death.png',
                        //         title : 'Cour des Âmes Déchus',
                        //         description : 'Complétez votre arsenal de la mort avec des puissantes carte des Âmes Déchues',
                        //         cost : 1400,
                        //         type : 'First',
                        //         startdate : '2023-09-13'
                        //     },
                        //     {
                        //         id : 8,
                        //         image : 'order.png',
                        //         title : 'Tribunal Archerande',
                        //         description : "Enrichissez votre collection de cartes du célèbre Tribunal Archerande",
                        //         cost : 1400,
                        //         type : 'First',
                        //         startdate : '2023-09-13'
                        //     },
                        //     {
                        //         id : 9,
                        //         image : 'lies.png',
                        //         title : 'Légion des Ombres Éternelles',
                        //         description : 'Rejoignez la Légion des Ombres Éternelles et renforcez votre deck avec des cartes redoutables.',
                        //         cost : 1400,
                        //         type : 'First',
                        //         startdate : '2023-09-13'
                        //     },
                        //     {
                        //         id : 10,
                        //         image : 'life.png',
                        //         title : 'Requiem des Guérisseurs Octecite',
                        //         description : 'Soignez vos blessures et renforcez vos alliés avec les cartes du Requiem des Guérisseurs Octecite, une extension dédiée à la guérison et à la vitalité',
                        //         cost : 1400,
                        //         type : 'First',
                        //         startdate : '2023-09-13'
                        //     },
                        //     {
                        //         id : 11,
                        //         image : 'truth.png',
                        //         title : 'Codex des Secrets Révélés',
                        //         description : 'Découvrez la vérité cachée et révelez les cartes de votre adversaires avec les cartes du Codex des Secrets Révélés, une extension dédiée à la connaissance et à la révélation',
                        //         cost : 1400,
                        //         type : 'First',
                        //         startdate : '2023-09-13'
                        //     },
                        //     {
                        //         id : 12,
                        //         image : 'order.png',
                        //         title : 'Tribunal Archerande',
                        //         description : "Enrichissez votre collection de cartes du célèbre Tribunal Archerande",
                        //         cost : 1400,
                        //         type : 'First',
                        //         startdate : '2023-09-13'
                        //     },
                        //     {
                        //         id : 13,
                        //         image : 'lies.png',
                        //         title : 'Légion des Ombres Éternelles',
                        //         description : 'Rejoignez la Légion des Ombres Éternelles et renforcez votre deck avec des cartes redoutables.',
                        //         cost : 1400,
                        //         type : 'First',
                        //         startdate : '2023-09-13'
                        //     },
                        //     {
                        //         id : 14,
                        //         image : 'life.png',
                        //         title : 'Requiem des Guérisseurs Octecite',
                        //         description : 'Soignez vos blessures et renforcez vos alliés avec les cartes du Requiem des Guérisseurs Octecite, une extension dédiée à la guérison et à la vitalité',
                        //         cost : 1400,
                        //         type : 'First',
                        //         startdate : '2023-09-13'
                        //     },
                        //     {
                        //         id : 15,
                        //         image : 'truth.png',
                        //         title : 'Codex des Secrets Révélés',
                        //         description : 'Découvrez la vérité cachée et révelez les cartes de votre adversaires avec les cartes du Codex des Secrets Révélés, une extension dédiée à la connaissance et à la révélation',
                        //         cost : 1400,
                        //         type : 'First',
                        //         startdate : '2023-09-13'
                        //     },
                        // ]
                        // this.setState({ expansions: expansionsDummy })

                    } else {
                        //set error
                    }
                }
            })
            .catch(error => {
                console.error('Products could not be fetched:', error)
                this.setProducts(Object.values(products_t), 'products')
            })

    }

    formatProducts(array) {
        const lng = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en'
        var formatedArray = []
        var product = {}

        // setting dynamic property name for potential future language implementation - provided the entry exists in the database and is fetched
        const p_lng_name = `p_${lng}_name`
        const p_lng_description = `p_${lng}_description`

        array.forEach(item => {
            const pname = item[p_lng_name]
            const pdescription = item[p_lng_description]
            const ptype = item.ename
            const pcost = (item.cost / 100)

            product.id = item.id
            product.image = item.image
            product.title = pname
            product.description = pdescription
            product.cost = pcost
            product.type = ptype
            product.startdate = item.start_date

            formatedArray.push(product)
        })

        return formatedArray
    }

    handleModal(value) {
        // console.log('New value of modal is:', value)

        this.setState({ modalShow: value })
    }

    /* setProducts arguments :
        array : array used for proceesing (either from fetch or local translations)
        isFetched : used to determine which object properties are used for render - render local data if error
        component : used to get last position the component was in before update
    */
    setProducts(array, component, isFectched = false) {
        var lng = localStorage.getItem('i18nextLng')
        var products = []
        var expansions = []

        const { currentPdtPosition } = this.state

        let productsValues = array
        let midArrayPos = Math.floor(array.length / 2)
        let basePosition = midArrayPos !== 0 ? -(midArrayPos) : midArrayPos;

        let currentPosition = 0
        switch (component) {
            case 'products':
                // console.log('Unprocessed products are: ', array)
                currentPosition = currentPdtPosition
                break;
            default:
                break;
        }
        let selectLastPos = midArrayPos + 1 + currentPosition

        // console.log('selectLastPos: ', selectLastPos)

        const sortedProducts = this.handleProductPosition(productsValues, currentPosition)

        sortedProducts.map(productItem => {
            var pname = productItem.title
            var pdescription = productItem.text
            const img1 = require('../img/pulses/' + productItem.image);
            let isBought = false
            if (this.state.bought !== null && this.state.bought.find(boughtItem => boughtItem.props.id === productItem.id)) {
                isBought = true
            }

            var ptype = ''
            var pcost = 24.90
            if (isFectched) {
                pname = lng === 'fr' ? productItem.p_fr_name : productItem.p_en_name
                pdescription = lng === 'fr' ? productItem.p_fr_description : productItem.p_en_description
                ptype = ' - ' + productItem.ename
                pcost = (productItem.cost / 100)
            }

            const product = (
                <Product
                    key={productItem.id}
                    class={productItem.id === midArrayPos + 1 ? 'product_selected' : ''}
                    img={img1}
                    position={basePosition}
                    title={pname + ptype}
                    text={pdescription}
                    cost={pcost}
                    isBought={isBought}
                    id={productItem.id}
                    handleRemove={(e) => this.handleRemove(e, component)}
                />
            )
            switch (component) {
                case 'products':
                    products.push(product)
                    break;
                case 'expansions':
                    expansions.push(product)
                    break;
                default:
                    break;
            }
            basePosition++
        });


        switch (component) {
            case 'products':
                const selectedProduct = products.find(productItem => productItem.props.id === selectLastPos);
                this.setState({
                    products: products,
                    selectedProduct: selectedProduct
                });
                break;
            case 'expansions':
                const selectedExpansion = expansions.find(productItem => productItem.props.id === selectLastPos);
                this.setState({
                    expansions: expansions,
                    selectedExpansion: selectedExpansion
                });
                break;
            default:
                break;
        }

        // console.log('selectedExpansion is: ', this.state.selectedExpansion)

    }

    handleProductPosition = (array, lastPos) => {
        const firstPart = array.slice(lastPos);
        const secondPart = array.slice(0, lastPos);

        return firstPart.concat(secondPart);
    }

    handleRemove = (elem, component) => {
        const { products, expansions } = this.state

        var array = []
        var products2 = []
        var newBought = []
        var selected = []
        var count = 0

        switch (component) {
            case 'products':
                array = products
                break;
            case 'expansions':
                array = expansions
                break;
            default:
                break;
        }

        array.forEach(p => {
            var nBought = p.props.id === elem.props.id ? false : p.props.isBought
            var p2 = <Product
                img={p.props.img}
                position={p.props.position}
                title={p.props.title}
                text={p.props.text}
                isBought={nBought}
                onBoughtList={false}
                id={p.props.id}
                handleRemove={(e) => this.handleRemove(e, component)}
            />
            if (nBought) {
                var p3 = <Product
                    img={p.props.img}
                    position={count}
                    title={p.props.title}
                    text={p.props.text}
                    isBought={nBought}
                    onBoughtList={true}
                    id={p.props.id}
                    handleRemove={(e) => this.handleRemove(e, component)}
                />
                count += 1
                newBought.push(p3)

            }

            products2.push(p2)
            if (p.props.position == 0) {
                selected = p2
            }
        })

        elem !== undefined && console.log(elem.props)


        this.setState(prevState => ({
            [component === 'products' ? 'products' : 'expansions']: products2,
            [component === 'products' ? 'selectedProduct' : 'selectedExpansion']: selected,
            bought: newBought
        }));

    }

    // refactored logic for remove from cart for simlicity purposes. Not yet Used
    removeFromCart = (productId) => {
        const { bought } = this.state

        // const itemToRemove = bought.find(cartItem => cartItem.props.id === productId)
        const newCart = bought.filter(cartItems => cartItems.props.id != productId)

        this.setState({ bought: newCart })
    }

    handleRight(event, component) {
        const { products, expansions, currentPdtPosition, currentExpPosition } = this.state

        var array = []
        var currentPosition = 0
        var products2 = []
        switch (component) {
            case 'products':
                currentPosition = currentPdtPosition
                products2 = [...products]
                array = products
                break;
            case 'expansions':
                currentPosition = currentExpPosition
                products2 = [...expansions]
                array = expansions
                break;
            default:
                break;
        }
        var select

        let mostRightPos = Math.ceil(this.state.products.length / 2)  // +2
        let mostLeftPos = -Math.floor(this.state.products.length / 2) // -3

        // determines current position to keep when lang is changed
        var currentPos = currentPosition === mostRightPos - 1 ? mostLeftPos : currentPosition + 1


        array.forEach((p, index) => {
            var oldPosition = p.props.position

            if (oldPosition === mostLeftPos) {
                oldPosition = mostRightPos
            }
            if (oldPosition === 1) {
                select = products2[index]

            }
            products2[index] = (
                <Product
                    img={p.props.img}
                    position={oldPosition - 1}
                    title={p.props.title}
                    text={p.props.text}
                    isBought={p.props.isBought}
                    id={p.props.id}
                    handleRemove={(e) => this.handleRemove(e, component)}
                />
            )
        })


        this.setState({
            [component === 'products' ? 'products' : 'expansions']: products2,
            [component === 'products' ? 'selectedProduct' : 'selectedExpansion']: select,
            currentPosition: currentPos
        });


    }
    handleLeft(event, component) {
        const { products, expansions, currentPdtPosition, currentExpPosition } = this.state

        var array = []
        var currentPosition = 0
        var products2 = []
        var select;
        switch (component) {
            case 'products':
                currentPosition = currentPdtPosition
                products2 = [...products]
                array = products
                break;
            case 'expansions':
                currentPosition = currentExpPosition
                products2 = [...expansions]
                array = expansions
                break;
            default:
                break;
        }

        let mostRightPos = Math.ceil(this.state.products.length / 2)
        let mostLeftPos = -Math.floor(this.state.products.length / 2)

        // determines current position to keep when lang is changed
        var currentPos = currentPosition === mostLeftPos ? mostRightPos - 1 : currentPosition - 1

        array.forEach((p, index) => {
            var oldPosition = p.props.position
            if (oldPosition === mostRightPos) {
                oldPosition = mostLeftPos
            }
            if (oldPosition === -1) {
                select = products2[index]
            }
            products2[index] = (
                <Product
                    img={p.props.img}
                    position={oldPosition + 1}
                    title={p.props.title}
                    text={p.props.text}
                    isBought={p.props.isBought}
                    id={p.props.id}
                    handleRemove={(e) => this.handleRemove(e, component)}
                />
            )
        })


        this.setState({
            [component === 'products' ? 'products' : 'expansions']: products2,
            [component === 'products' ? 'selectedProduct' : 'selectedExpansion']: select,
            currentPosition: currentPos
        });

    }

    // updated function for addToCart any product; base or upcoming expansion. Currently used for expansions
    addToCart = (productId) => {
        const { expansions, bought } = this.state
        const isProductInCart = bought.find(cartItem => cartItem.props.id === productId) // to be changed with cart state with only values and no JSX

        if (!isProductInCart) {
            const newCartItem = expansions.find(exp => exp.id === productId)
            // console.log('newCartItem is: ', newCartItem)

            // temporary code below for current Cart Logic. Later, only state will be changed
            const img1 = require('../img/pulses/' + newCartItem.image)
            const newBought = <Product
                key={newCartItem.id}
                img={img1}
                position={bought.length}
                title={newCartItem.title}
                text={newCartItem.description}
                isBought={true}
                onBoughtList={true}
                id={newCartItem.id}
                handleRemove={(e) => this.handleRemove(e, 'products')}
            />
            //END of temporary code


            this.setState(prevState => ({
                cart: [...prevState.cart, newCartItem],
                bought: [...prevState.bought, newBought]
            }))
        }
    }

    handleAddToCart = (component) => {
        const { selectedProduct, selectedExpansion } = this.state
        if ((selectedProduct && !selectedProduct.props.isBought) || (selectedExpansion && !selectedExpansion.props.isBought)) {
            const { products, expansions, bought } = this.state
            let isItemInCart = false
            let item = {}
            let array = []

            switch (component) {
                case 'products':
                    item = products.find((productItem) => productItem.props.position === 0)
                    isItemInCart = bought.find((cartItem) => cartItem.props.id === item.props.id);
                    array = products
                    break;
                case 'expansions':
                    item = expansions.find((expansionItem) => expansionItem.props.position === 0)
                    isItemInCart = bought.find((cartItem) => cartItem.props.id === item.props.id);
                    array = expansions
                    break;

                default:
                    break;
            }

            // console.log('Item added to Cart ID is: ', item.props.id)
            // console.log('isIteminCart: ', isItemInCart)

            var nBought = item.props.position == 0 ? true : item.props.isBought

            // new item in cart
            const newBought = <Product
                key={item.props.title + item.props.id}
                img={item.props.img}
                position={bought.length}
                title={item.props.title}
                text={item.props.text}
                isBought={nBought}
                onBoughtList={true}
                id={item.props.id}
                handleRemove={(e) => this.handleRemove(e, component)}
            />

            const selected = <Product
                key={item.props.id}
                img={item.props.img}
                position={item.props.position}
                title={item.props.title}
                text={item.props.text}
                isBought={nBought}
                onBoughtList={false}
                id={item.props.id}
                handleRemove={(e) => this.handleRemove(e, component)}
            />

            // console.log('Array before Add: ', array)
            var products2 = array.filter(p => p.props.id !== item.props.id) // should contain 5 products
            var refreshedArray = [...products2, selected]

            refreshedArray.sort((a, b) => a.props.position - b.props.position)




            if (!isItemInCart) {
                this.setState((prevState) => ({
                    [component === 'products' ? 'products' : 'expansions']: refreshedArray,
                    [component === 'products' ? 'selectedProduct' : 'selectedExpansion']: selected,
                    bought: [...prevState.bought, newBought],
                    cart: [...prevState.cart, newBought]
                }))
            }
        }
    }

    handleToCheckout() {
        if (this.state.bought.length > 0) {
            var id = []
            this.state.bought.forEach(
                p => id.push(p.props.id)
            )
            const tid = JSON.stringify(id).toString().slice(1, -1);
            window.location.href = '/checkout?id=' + tid

            // this.props.history.push({
            //     pathname: '/checkout',
            //     search: '?id=' + tid, // Use search to pass query parameters
            //     state: { data: this.state.bought },
            // });
        }
    }

    setPrice(basePrice, quantity) {
        var price = basePrice * quantity;

        price = Math.round(price * 100) / 100

        // console.log("the price base is : " + price)

        // discounts {quantiy : discount in %}
        var discounts = {
            2: 9.9,
            3: 20.8,
            4: 32.7,
            5: 45.6,
            6: 59.5,
        };

        var keys = Object.keys(discounts);

        var i = keys.length - 1;

        while (quantity > 1 && i >= 0) {

            var key = keys[i];
            var value = discounts[key];

            if (quantity / key >= 1) {
                price -= value;
                price = Math.round(price * 100) / 100

                quantity -= key;
            } else {
                i--;

            }
        }

        // console.log("price : " + price + " quantity : " + quantity);

        return price;
    }

    handleTouchStart = (event) => {
        const touch = event.touches[0];

        this.setState({ touchStartX: touch.clientX });
    };

    handleTouchMove = (event) => {
        if (!this.state.touchStartX) return;

        const touch = event.touches[0];


        this.setState({ touchEndX: touch.clientX });
    };

    handleTouchEnd = () => {
        if (!this.state.touchStartX || !this.state.touchEndX) return;

        const treshold = 70;

        const swipeDistance = this.state.touchEndX - this.state.touchStartX;

        if (swipeDistance > treshold) {
            this.handleLeft();
        } else if (swipeDistance < -treshold) {
            this.handleRight();
        }



        this.setState({
            touchStartX: null,
            touchEndX: null,
        });
    };

    hideAndShowText() {


        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    getProductCards = (productId) => {
        const { cards } = this.props // passed from Main.jsx state
        // console.log('props cards: ', cards)

        var cardList = []

        // filter all cards to match cards from product
        cardList = cards.filter(c => c.product === productId)

        return cardList
    }

    getDiscounts = () => {
        fetch("/api.php", {
            method: "POST",
            body: JSON.stringify({
                action: "getDiscountInfos"
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("Network error");
            }
            return response.json();
        })
        .then(data => {
            this.setState({
                database: data
            })
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
            //throw new Error("Error fetching data");
          });
    }

    compute_price = (items, database) => {


        var cat_list = this.list_categories(items, database); 

   
        // list id => saved_money
        var saved_list = {};
    
        // first look for specific offers (precedence=3)
        for (var prod_id in items) {
            var res = this.compute_single_price(prod_id, database, cat_list["latest"]);
    
            // keep track when a reduction has been found
            var base_price = res["base_price"];
            var new_price = res["new_price"];
            
            if (base_price !== new_price) {
                saved_list[prod_id] = {
                    "base_price": base_price,
                    "new_price": new_price
                };
    
                // remove from $cat_list if has a reduction
                var category = database["product"][prod_id]["name"];
    
                if (category === "Base") {
                    delete cat_list["pulse"][prod_id];
                } else {
                    delete cat_list["ext"][prod_id];
                }
            }
        }
    
        var price = 0;


    
        // count prices for specific offers
        for (var price_info in saved_list) {
            price += price_info["new_price"];
        }
    
        delete cat_list["latest"]; // no more needed
    
        // then look for grouped offers (precedence=2;1)
        price += this.compute_grouped(cat_list["pulse"], database, "qty_required_pulse");


        const product = Object.values(database)[0].product
    
        // finally, add basic prices for the rest
        for (var cat in cat_list) {
            cat_list[cat].forEach(id_prod => {

                var prod = Object.values(product)
                var toAdd = 0;
                

                for (var i=0; i < prod.length; i++){
                    var p = prod[i]
                    if(p["id"] == id_prod){
                        toAdd = parseInt(p["cost"])
                    }

                }
                price += toAdd
            })
        }

    
        return (price / 100).toFixed(2)
    }

    list_categories = (items, dc_infos) => {

        //var prod_array = dc_infos["product"];

        var prod_array = Object.values(dc_infos)[0].product

    
        var pulse_list = new Set();
        var extension_list = new Set();
    
        var latest_date = 0;
        var latest_list = new Set();
    
        for (var id in prod_array) {
            id = parseInt(id)

            if (items.includes(id)) {
                if (prod_array[id]["name"] === "Base") {
                    pulse_list.add(id);
                } else {
                    extension_list.add(id);
                }
            }
            if (prod_array[id]["start_date"] > latest_date) {
                latest_date = prod_array[id]["start_date"];
            }
        }
    
        for (var id in prod_array) {
            if (prod_array[id]["start_date"] == latest_date) {
                latest_list.add(id);
            }
        }

        return {
            "pulse": pulse_list,
            "ext": extension_list,
            "latest": latest_list
        };
    }

    get_same_prices = (items_of_same_cat, dc_infos) => {
        var products = dc_infos["product"];
        var acc = 0;
    
        for (var id_prod in items_of_same_cat) {
            acc += products[id_prod]["cost"];
        }
    
        return acc;
    }

    compute_grouped(items_of_same_cat, dc_infos, field_name) {
        var count = items_of_same_cat.size;
    
        var products = Object.values(dc_infos)[0].product
        var discounts = Object.values(dc_infos)[0].discount


    
        var dc_keys = [];
    
        var i = 0;
        for (var id in discounts) {
            if (discounts[id][field_name] != null) {
                dc_keys.push(id);
                i++;
            }
        }

    
        var dc_keys_count = dc_keys.length;
        var price = 0;

    
        var i = dc_keys_count - 1;
        while (count > 0 && i >= 0) {

            var offer = discounts[dc_keys[i]];



            var qty_required = offer[field_name];

    
            // found appliable discount
            if (count >= qty_required) {
                price += this.set_price(
                    this.get_same_prices(items_of_same_cat, dc_infos),
                    offer.type, 
                    offer.dc_value            
                );
    
                count -= qty_required;
                //items_of_same_cat = items_of_same_cat.slice(qty_required);
                items_of_same_cat.delete(qty_required);
            } else {
                i--;
            }
        }
    
        return price;
    }

    set_price = (old, type, value) => {
        var res = old;
        
        switch (type) {
            case "percentage":
                res -= Math.round(old * value / 100);
                break;
        
            case "amount":
                res -= value;
                break;
        }
    
        // just in case a discount is fucked up
        if (res < 0) {
            //res = old;
        }
    
        return res;
    }

    is_valid_date(start_date, end_date) {
        const currentDate = new Date().toISOString().slice(0, 19).replace("T", " ");
    
        if (!end_date) {
            const maxTimestamp = new Date(Number.MAX_SAFE_INTEGER);
            end_date = maxTimestamp.toISOString().slice(0, 19).replace("T", " ");
        }
        
        return start_date <= currentDate && currentDate <= end_date;
    }

    compute_single_price(id, dc_infos, latest_list) {
    

        var product = Object.values(dc_infos)[0].product
    
        var result = {
            "id": id,
            "base_price": product.cost,
            "new_price": product.cost
        };
        
        // Check if offer_name match :
        //  - in reverse: first if specific/last
        //      - if specific: check id_offer_target
        //  - continue if not matching
        for (var offer in dc_infos.discount) {
            var name = offer.offer_name;
            switch (name) {
                case "specific":
                    if (offer.id_offer_target == id) {
                        if (!this.is_valid_date(offer.start_date, offer.end_date)) break;
    
                        result.new_price = this.set_price(
                            result.base_price, 
                            offer.type, 
                            offer.dc_value
                        );
                    }
                    break;
                case "last-ext":
                    if (id in latest_list) {
                        if (!this.is_valid_date(offer.start_date, offer.end_date)) break;
    
                        result.new_price = this.set_price(
                            result.base_price, 
                            offer.type, 
                            offer.dc_value
                        );
                    }
                    break;
            }
        }
    
        //console.log("Res: " + id + "=" + result["base_price"] + " -> " + result["new_price"] + "\n");
        return result;
    }

    render() {

       


        const { isOpen, products, expansions, selectedProduct, bought } = this.state;
        const open = isOpen ? "open" : "close"
        const text = isOpen ? t('buy_status_text_open') : t('buy_status_text_close')

        // var valExp = expansions.length !== 0 ? (
        //     <div className='buy_expensions_container'>
        //         <div className="buy_select_products_wrapper">
        //             {expansions}
        //         </div>

        //         <div className='buy_select_title_container'>
        //             {expansions.length > 1 &&
        //                 <>
        //                     <div className='buy_select_left buy_select_controls' onClick={(e) => { this.handleLeft(e, 'expansions') }}>
        //                         <i className="bi bi-caret-left-fill"></i>
        //                     </div>
        //                     <div className='buy_select_right buy_select_controls' onClick={(e) => { this.handleRight(e, 'expansions') }} >
        //                         <i className="bi bi-caret-right-fill"></i>
        //                     </div>
        //                 </>
        //             }
        //             <div className='buy_select_title'>
        //                 {selectedExpansion && selectedExpansion.props.title}
        //             </div>
        //         </div>

        //         <div className={'buy_selected_text_container buy_selected_text_container_' + open}>

        //             <div className={'buy_selected_text buy_selected_text_' + open} id='selected_text'>
        //                 {selectedExpansion && selectedExpansion.props.text}
        //             </div>
        //             <button className="button_show" id='selected_btn' onClick={this.hideAndShowText}>{text}</button>
        //         </div>

        //         <div className={('buy_select_button_valider_container')}>
        //             <div
        //                 className={('buy_select_button_valider_text buy_select_button_valider_image button buttonShadows gradientOrangePink') + (selectedExpansion && selectedExpansion.props.isBought ? ' buttonDisabled' : '')}
        //                 onClick={(e) => { this.handleAddToCart(e, 'expansions') }}
        //             >
        //                 <i className="bi bi-cart-plus"></i><span>{t('buy_add_to_cart')}</span>
        //             </div>
        //         </div>
        //     </div>
        // ) : ''

        /* console.log(expansions) */

        const valExpansions = expansions.length > 0 ? (
            <div className='buy_expansions_container'>
                <h2 className='p-2'>{t('buy_latest_expansions')}</h2>
                <CardList className='d-grid' itemsPerPage={3} items={expansions} cards={this.props.cards} onAddToCart={this.addToCart} />
                {/* <ResponsiveSlider items={expansions} onAddToCart={this.addToCart} /> */}
            </div>
        ) : ''

        var productCards = []

        productCards = selectedProduct !== null && this.getProductCards(selectedProduct.props.id)
        // console.log(productCards)
        
        var lng = localStorage.getItem('i18nextLng') !== undefined ? localStorage.getItem('i18nextLng').slice(0, 2) : 'en'

        // Product Cards Tooltip
        const popover = (
            <Popover className="buy_product_cards" id="popover-basic">
                <Popover.Header as="h3">{t('buy_popover_header')}</Popover.Header>
                <Popover.Body>
                    {t('buy_popover_text')}
                    <div className='buy_product_cardsBox'>
                    {productCards && productCards.map(c => {
                        const img1 = require('../img/pulses/' + c.pulse + '/sample/' + lng + '/' + c.image)
                        return (
                            <AltarCard
                                key={'p_' + c.image}
                                title={c.title}
                                text={''}
                                image={img1}
                                alt={c.image}
                                pulse={c.pulse}
                            />
                        )
                    })}
                    </div>
                </Popover.Body>
            </Popover>
        );

        const database = this.state.database

        var items = []
        var l1 = 0
        var l2 = 0
        var l3 = 0
        var l4 = 0
        var l5 = 0
        var l6 = 0
        
        if (database!=null){
            items = [1]
            l1 = this.compute_price(items, database)
            items = [1,2]
            l2 = this.compute_price(items, database)
            items = [1,2,3]
            l3 = this.compute_price(items, database)
            items = [1,2,3,4]
            l4 = this.compute_price(items, database)
            items = [1,2,3,4,5]
            l5 = this.compute_price(items, database)
            items = [1,2,3,4,5,6]
            l6 = this.compute_price(items, database)
            
        }


        return (
            <div id="purchase" className='buy_container'>
                {/* Discount Container */}
                <div className='buy_rabais_container buy_rabais_image'>
                    <div className='buy_rabais_text_container'>
                        <p>
                            {t('buy_rabais_individuel')} {l1} CHF
                        </p>
                        <p>
                            {t('buy_rabais_duo')} {l2}  CHF
                        </p>
                        <p>
                            {t('buy_rabais_3')} {l3}  CHF
                        </p>
                        <p>
                            {t('buy_rabais_4')} {l4}  CHF
                        </p>
                        <p>
                            {t('buy_rabais_5')} {l5}  CHF
                        </p>
                        <p>
                            {t('buy_rabais_6')} {l6}  CHF
                        </p>
                    </div>
                </div>

                {/* Base Products Carousel */}
                <div className='buy_select_container' onTouchStart={this.handleTouchStart} onTouchMove={this.handleTouchMove} onTouchEnd={this.handleTouchEnd}>
                    <div className="buy_select_products_wrapper">
                        {products}
                    </div>

                    <div className='buy_select_title_container'>
                        <div className='buy_select_left buy_select_controls' onClick={(e) => { this.handleLeft(e, 'products') }}>
                            <i className="bi bi-caret-left-fill"></i>
                        </div>
                        <div className='buy_select_right buy_select_controls' onClick={(e) => { this.handleRight(e, 'products') }} >
                            <i className="bi bi-caret-right-fill"></i>
                        </div>
                        <div className='buy_select_title'>
                            {selectedProduct && (
                                <>
                                    {selectedProduct.props.title}
                                    <OverlayTrigger trigger={['click', 'focus']} placement="top" overlay={popover} rootClose>
                                        <span className='text-decoration-underline'><i className="bi bi-question-circle mx-2"></i><span className='text-decoration-none'>{t('buy_product_cards')}</span></span>
                                    </OverlayTrigger>
                                </>
                            )}
                        </div>
                    </div>

                    <div className={'buy_selected_text_container buy_selected_text_container_' + open}>

                        <div className={'buy_selected_text buy_selected_text_' + open} id='selected_text'>
                            {selectedProduct && selectedProduct.props.text}
                        </div>
                        <button className="button_show" id='selected_btn' onClick={this.hideAndShowText}>{text}</button>
                    </div>

                    <div className={('buy_select_button_valider_container')}>
                        <div
                            className={('buy_select_button_valider_text buy_select_button_valider_image button buttonShadows gradientOrangePink') + (selectedProduct && selectedProduct.props.isBought ? ' buttonDisabled' : '')}
                            onClick={(e) => { this.handleAddToCart('products') }}
                        >
                            <i className="bi bi-cart-plus"></i><span>{t('buy_add_to_cart')}</span>
                        </div>
                    </div>
                </div>

                {/* Expansion Products Carousel */}
                {valExpansions}

                {/* Cart Container */}
                <div className='buy_list_buy_container'>
                    <div className='buy_list_background_container'>
                        {/* Added-to-Cart list */}
                        {bought}
                        {/* <Cart items={this.state.cart} onRemove={this.removeFromCart}/> */}
                        <div
                            className='buy_list_background_text'
                        >
                            {
                                /*
                                                            {t('buy_total_price')} : {this.setPrice(24.90, this.state.bought.length)} CHF
                                */
                            }
                        </div>
                    </div>
                    <div
                        className='buy_list_button_valider_container'
                    >
                        <div
                            className='buy_list_button_valider_text button buttonShadows gradientOrangePink buy_list_button_valider_image'
                            onClick={this.handleToCheckout}
                        >
                            {t('buy_to_checkout')}
                        </div>
                    </div>
                </div>

            </div>
        )
    }

}

export default withTranslation('cards')(Buy)

