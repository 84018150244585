import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";

import ReturnButton from "./returnButton";
import Product from "./product";
import ImageTruth from '../img/pulses/truth.png'
import ImageLies from '../img/pulses/lies.png'
import ImageOrder from '../img/pulses/order.png'
import ImageChaos from '../img/pulses/chaos.png'
import ImageLife from '../img/pulses/life.png'
import ImageDeath from '../img/pulses/death.png'

// Translations Module
import { t } from 'i18next';
import { withTranslation } from "react-i18next";

function CheckoutForm(items) {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  var database = null

  

  const setPrice = (basePrice, quantity) => {
    // WORK IN PROGRESS fetch prices & discounts from server
    
    var price = basePrice * quantity;

    price = Math.round(price * 100) / 100

    console.log("the price base is : " + price)

    // discounts {quantiy : discount in %}
    var discounts = {
      2: 9.9,
      3: 20.8,
      4: 32.7,
      5: 45.6,
      6: 59.5,
    };

    var keys = Object.keys(discounts);

    var i = keys.length - 1;

    while (quantity > 1 && i >= 0) {

      var key = keys[i];
      var value = discounts[key];

      if (quantity / key >= 1) {
        price -= value;
        price = Math.round(price * 100) / 100

        quantity -= key;
      } else {
        i--;

      }
    }

    console.log("price : " + price + " quantity : " + quantity);

    return price;
  }


  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage(t('checkoutForm_success'));
          break;
        case "processing":
          setMessage(t('checkoutForm_processing'));
          break;
        case "requires_payment_method":
          setMessage(t('checkoutForm_method_required'));
          break;
        default:
          setMessage(t('checkoutForm_fail'));
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "https://2.altarcg.com/validation",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage(error.type);
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  // Drawing Design
  var product = []
  items.items.forEach(p => {
    // UPDATED CODE for any product or expansion - switch could be removed.

    // const p = <Product
    //   key={p.id}
    //   img={p.image}
    //   onPaiement={true}
    //   class={"checkout_buy_" + p.pulse}
    //   title={p.title}
    // />
    // product.push(p)


    switch (p.id) {
      case "1":
        var p2 = <Product
          img={ImageTruth}
          onPaiement={true}
          class={"checkout_buy_truth"}
        />
        product.push(p2)
        break;
      case "2":
        var p2 = <Product
          img={ImageLies}
          id={2}
          onPaiement={true}
          class={"checkout_buy_lies"}
        />
        product.push(p2)
        break;
      case "3":
        var p2 = <Product
          img={ImageOrder}
          id={2}
          onPaiement={true}
          class={"checkout_buy_order"}
        />
        product.push(p2)
        break;
      case "4":
        var p2 = <Product
          img={ImageChaos}
          id={2}
          onPaiement={true}
          class={"checkout_buy_chaos"}
        />
        product.push(p2)
        break;
      case "5":
        var p2 = <Product
          img={ImageLife}
          id={2}
          onPaiement={true}
          class={"checkout_buy_life"}
        />
        product.push(p2)
        break;
      case "6":
        var p2 = <Product
          img={ImageDeath}
          id={2}
          onPaiement={true}
          class={"checkout_buy_death"}
        />
        product.push(p2)
        break;
    }
  }

  )

  var price = setPrice(24.9, items.items.length)

  if (items.items.length % 6 == 0) {

  } else if (items.items.length % 2 == 0) {

  }
  // END Drawing Design

  return (
    <>
      <ReturnButton customClass={"checkout_bouton_retour"} />
      <div className="checkout_payment_container">
        <form id="payment-form" onSubmit={handleSubmit} className="checkout_payement_form">
          <LinkAuthenticationElement
            id="link-authentication-element"
            onChange={(e) => setEmail(e.target.value)}
            className="checkout_email"
          />
          <PaymentElement id="payment-element" options={paymentElementOptions} />
          <button disabled={isLoading || !stripe || !elements} id="submit" className="checkout_bouton_submit button gradientOrange buttonRadius">
            <span id="button-text">
              {isLoading ? <div className="spinner" id="spinner"></div> : t('checkoutForm_pay_now')}
            </span>
          </button>
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </form>
        <div className="checkout_pulses_container">
          {product}
          <div className="checkout_price">
            {t('buy_total_price')} : {price} CHF
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(CheckoutForm)