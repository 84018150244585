import React, { useState, useEffect } from 'react';
import { Modal, Card, CardGroup, Badge, Button } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';

import SampleImg from '../img/icons/sampleimg.png'

import AltarCard from './card';

import { t } from 'i18next';

function CardList({ itemsPerPage, items, cards, onAddToCart }) {
    const lng = localStorage.getItem('i18nextLng') !== undefined ? localStorage.getItem('i18nextLng').slice(0, 2) : 'en'

    // modal logic
    const [isShown, setVisibility] = useState(false);
    const [modalData, setModelData] = useState(null);
    const [modalCards, setModelCards] = useState([])

    // pagination logic
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
// 
    useEffect(() => {
        console.log('productCards on click: ', modalCards)
    }, [modalCards])


    return (
        <div>
            <CardGroup className='buy_expansions_cardslider mb-2 d-flex justify-content-center overflow-x-auto'>
                {currentItems.map((exp) => (
                    <Card key={exp.id} className='buy_expansion_cards col-md-4 d-flex' style={{ "max-width": "18rem" }}>
                        <div className="position-relative">
                            <Card.Img variant="top" src={require('../img/pulses/' + exp.image)} alt={exp.image} onError={(e) => { e.target.src = require('../img/icons/sample.png') }} />
                            <Badge className='buy_expansion_cards_badge' bg="danger">{t('buy_new_badge')}</Badge>
                        </div>
                        <Card.Body>
                            <Card.Title>{exp.title}</Card.Title>
                            <Card.Text className='buy_expansion_cards_text'>
                                {exp.description}
                            </Card.Text>
                        </Card.Body>
                        <Card.Body>
                            <button
                                className='mb-2 buy_expansion_modal_link'
                                onClick={() => {
                                    const cardList = cards.filter(c => c.product === exp.id) // replace by exp.id when tested
                                    setModelCards(cardList)
                                    setVisibility(true);
                                    setModelData(exp);
                                }}
                            >
                                {t('buy_see_more')}
                            </button>
                            <div className='d-inline float-end'><span className='buy_expansion_cards_base_price'>CHF {exp.cost}</span><span className="buy_expansion_cards_discounted_price">CHF {exp.cost}</span></div>
                        </Card.Body>
                        <Card.Body>
                            <div className='button gradientOrange buttonRadius p-2' onClick={() => onAddToCart(exp.id)}>{t('buy_add_to_cart')}</div>
                        </Card.Body>
                        <Card.Footer>
                            <small className="text-muted">{t('buy_release_text') + ' ' + exp.startdate}</small>
                        </Card.Footer>
                    </Card>
                ))}
            </CardGroup>
            {modalData !== null && <Modal
                show={isShown}
                onHide={() => setVisibility(false)}
                fullscreen="md-down"
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName='buy_modal_expansion'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('buy_modal_expansion')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='border-bottom'>
                    <Row className='modal_expansion_info'>
                        <Col xs={6} md={4} className='mx-auto'>
                            <img src={require('../img/pulses/' + modalData.image)} alt={modalData.image} onError={(e) => { e.target.src = require('../img/icons/sampleimg.png') }} />
                        </Col>
                        <Col xs={12} md={8}>
                            <h2>{modalData.title}</h2>
                            <p>{modalData.description}</p>
                        </Col>
                    </Row>
                </Modal.Body>
                {modalCards !== null &&
                    <Modal.Body className='modal_expansion_cards'>
                            <div className='buy_product_cardsBox'>
                                {modalCards.map(c => {
                                    const img1 = require('../img/pulses/' + c.pulse + '/sample/' + lng + '/' + c.image)
                                    return (<AltarCard
                                        key={'p_' + c.image}
                                        title={c.title}
                                        text={''}
                                        image={img1}
                                        alt={c.image}
                                        pulse={c.pulse}
                                    />)
                                })}
                            </div>
                    </Modal.Body>
                }

                <Modal.Footer>
                    <div className='button gradientOrange buttonRadius buy_modal_add_to_cart_button' onClick={() => {
                        onAddToCart(modalData.id)
                        setVisibility(false)
                    }}
                    >
                        {t('buy_add_to_cart')}
                    </div>
                    <Button variant="danger" onClick={() => setVisibility(false)}>{t('modal_close')}</Button>
                </Modal.Footer>
            </Modal>}
            {/* Pagination Controls */}
            <div className='d-flex justify-content-center'>
                <ul className="pagination">
                    {Array.from({ length: Math.ceil(items.length / itemsPerPage) }).map((_, index) => (
                        <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
                            <button onClick={() => paginate(index + 1)} className="page-link">
                                {index + 1}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default CardList