/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

class Pulse extends React.Component {

    constructor(props) {
        super(props)
        this.handler = this.handler.bind(this)
        this.state = {
            fullScreen: false,
            active: false,
        }
        // this.ref = React.createRef()
    }

 
    handler() {
        this.setState({
            active: !this.state.active,
            hidden: !this.state.hidden
        })
        // if (this.ref.current) {
        //     this.ref.current.scrollInttoView({ behavior: 'smooth' })
        // }
    }

    handleClick(AltarCard) {
        this.setState({
            fullScreen: !this.state.fullScreen,
            card: AltarCard
        })
    }

    hideAndShowText = (event) => {
        var txt = document.getElementById(event.target.getAttribute("txt"));
        var btn = document.getElementById(event.target.getAttribute("id"));

        if (txt.style.textOverflow === "ellipsis" || txt.style.textOverflow === "") {
            btn.style.rotate = "-90deg";
            btn.style.translate = "0% 175%";

            txt.style.display = "inline";
            txt.style.webkitLineClamp  = "initial";
            txt.style.boxOrient = "initial";

            txt.style.textOverflow = "initial";
            txt.style.overflow = "visible";
        } else {
            btn.style.rotate = "90deg";
            btn.style.translate = "0% 25%";

            txt.style.display = "-webkit-box";
            txt.style.webkitLineClamp  = "2";
            txt.style.boxOrient = "vertical";

            txt.style.textOverflow = "ellipsis";
            txt.style.overflow = "hidden";
        }
    }

    render() {
        var valReturn = []

        var cards = []

        // Display open pulses with cards
        if (this.state.active) {
            const children = this.props.cards.sort((a, b) => a.props.title > b.props.title)
            for (let i = 0; i < children.length; i += 6) {
                // console.log(p)
                cards.push(
                    <Row xs={3} md={6} lg={6}>
                        <Col key={children[i]}>{children[i]}</Col>
                        <Col key={children[i + 1]}>{children[i + 1]}</Col>
                        <Col key={children[i + 2]}>{children[i + 2]}</Col>
                        <Col key={children[i + 3]}>{children[i + 3]}</Col>
                        <Col key={children[i + 4]}>{children[i + 4]}</Col>
                        <Col key={children[i + 5]}>{children[i + 5]}</Col>
                    </Row>
                )
            }

            const str = "cardPreview" + this.props.name + "Open";

            valReturn.push(
                <div className={str}>
                    <Image
                        className='pulsesPreviewsOpen'
                        src={this.props.img}
                        onClick={this.handler}
                    />
                    {cards}
                </div>
            )
        } 
        else {
            // Display closed pulses
            const str = "cardPreview" + this.props.name + "Close cardPreviewClose";
            const classText = "cardPreview" + this.props.name + "CloseText whiteText";

            valReturn.push(
                <div className={str}>
                    <ul>
                        <li>
                            <Image
                                className='pulsesPreviews'
                                src={this.props.img}
                                onClick={this.handler}
                            />
                        </li>
                        <li>
                            <p className='whiteText'>
                                {this.props.title}
                            </p>

                        </li>
                    </ul>
                </div>,
                <div className={classText} id={`hidden_txt${this.props.id}`}>
                    <p>
                        {this.props.text}
                    </p>
                </div>,

                <p 
                    className="hidden_btn" 
                    id={`hidden_btn${this.props.id}`} 
                    onClick={this.hideAndShowText} 
                    txt={`hidden_txt${this.props.id}`} 
                >&gt;</p>
            )
        }
        return valReturn

    }

}

export default Pulse
