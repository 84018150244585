/*

Author: Jonathan Buso

  This is the internalization/localization config file to process translations on the website.
  Translations are stored under: public/locales/{lng}   -   lng representing a language code.

  To add a language, simply create a folder with the corresponding language code:
  either "pure" such as - 'en', 'fr', 'de' or 'pt-BR' for more specific. For reference, see https://en.wikipedia.org/wiki/IETF_language_tag#List_of_common_primary_language_subtags
  
  Once the folder is created, copy 'translation.json' from another language then populate data
  in desired language.

  Lastly, update lngs in languageSelector.jsx

*/

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const fallbackLng = ["fr"];
const langs = ["fr", "en"]

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: false,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    react: {
      useSuspense: true,
    },
    fallbackLng: fallbackLng,
    supportedLngs: langs,
    interpolation: {
      escapeValue: false
    },
    ns: ['cards', 'translation'],
  })

  export default i18n;