
/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */

import React from 'react';
import Container from 'react-bootstrap/Container';

// Translations Module
import i18n, { t } from 'i18next';
import { withTranslation } from 'react-i18next';

//cards
import AltarCard from './card'
import Pulse from "./pulse"


class Cards extends React.Component {

    constructor(props) {
        super(props)

        this.handler = this.handler.bind(this)

        this.state = {
            fullScreen: false,
        }

        /*this.bounds = [
            {min:0,max:500,func:red},
            {min:501,max:850,func:orange},
            {min:851,func:green}
        ];*/
    }
    /*
    // define a resize function. use a closure for the lastBoundry determined.
    resizeFn(){
        var lastBoundry; // cache the last boundry used
        return function(){
            var width = window.innerWidth; // get the window's inner width
            var boundry, min, max;
            for(var i=0; i<bounds.length; i++){
                boundry = bounds[i];
                min = boundry.min || Number.MIN_VALUE;
                max = boundry.max || Number.MAX_VALUE;
                if(width > min && width < max 
                   && lastBoundry !== boundry){
                    lastBoundry = boundry;
                    return boundry.func.call(boundry);            
                }
            }
        }
    }

    wheel() {

        // set up all our vars which are shared across functions

        var containerEl = document.getElementById('wheel'),

        wedgeColors = [
            '#2980B9', // dark blue
            '#2ecc71', // green
            '#3498db', // blue
            '#34495e', // metal blue
            '#f1c40f', // yellow
            '#e74c3c', // red
            '#16A085', // fern
            //'#95a5a6',  // grey
            '#34495E', // wet ashpalt
            '#C0392B', // pomegranate
            '#e98b39' // orange
        ],

        numOfWedges = 10,
        wheelRadius = 230,
        maxAngularVelocity = 360 * 1.5,
        angularFriction = 0.75,
        angularVelocity = 360,
        lastRotation = 0,
        controlled = false, // set true for no autospin

        target,
        activeWedge,
        stage,
        layer,
        wheel,
        pointer,
        pointerTween,
        startRotation,
        startX,
        startY;


        // used for randomizing the colour array
        // Fisher-Yates (aka Knuth) Shuffle
        function shuffle(array) {
            var currentIndex = array.length, temporaryValue, randomIndex ;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;

                // And swap it with the current element.
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }

            return array;
        }

        // now we shuffle the color array
        shuffle( wedgeColors );

        function addWedge(n) {
        
        var angle = 360 / numOfWedges;

        var wedge = new Kinetic.Group({
            rotation: n * 360 / numOfWedges,
        });


        var wedgeBackground = new Kinetic.Wedge({
            radius: wheelRadius,
            angle: angle,
            fill: wedgeColors.pop(),
            //stroke: '#fff',
            //strokeWidth: 2,
            rotation: (90 + angle/2) * -1
        });

        wedge.add(wedgeBackground);

        var text = new Kinetic.Text({
            text: '0',
            fontFamily: 'Fredoka One',
            fontSize: 30,
            fill: '#fff',
            align: 'center',
            //stroke: '#fff',
            //strokeWidth: 2,
            opacity: 0.95,
            listening: false

        });
        
        text.offsetX(text.width()/2);
        text.offsetY(wheelRadius - 15);
        
        wedge.add(text);
        wheel.add(wedge);

        }

        function animate(frame) {
            // wheel
            var angularVelocityChange = angularVelocity * frame.timeDiff * (1 - angularFriction) / 1000;
            angularVelocity -= angularVelocityChange;

            if(controlled) {
                angularVelocity = ((wheel.getRotation() - lastRotation) * 1000 / frame.timeDiff);
            }
            else {
                wheel.rotate(frame.timeDiff * angularVelocity / 1000);
            }
            lastRotation = wheel.getRotation();
            
            // pointer
            var intersectedWedge = layer.getIntersection({
                x: stage.width()/2, 
                y: 50
            });
            
            if (intersectedWedge && (!activeWedge || activeWedge._id !== intersectedWedge._id)) {
                pointerTween.reset();
                pointerTween.play();
                activeWedge = intersectedWedge; 
                
            //$('#winner').text(activeWedge.parent.children[1].partialText);

            }
        }

        function init() {
        stage = new Kinetic.Stage({
            container: 'container',
            width: wheelRadius * 2,
            height: wheelRadius * 2 + 20 // plus 20 is for the pointer
        });
        layer = new Kinetic.Layer();
        wheel = new Kinetic.Group({
            x: stage.getWidth() / 2 ,
            y: wheelRadius + 20
        });

        for (var n = 0; n < numOfWedges; n++) {
            addWedge(n);
        }
        
        pointer = new Kinetic.Wedge({
            fill: '#dedede',
            //stroke: '#fff',
            //strokeWidth: 0,
            lineJoin: 'round',
            angle: 35,
            radius: 20,
            x: stage.getWidth() / 2,
            y: 22,
            rotation: -105
        });

        // add components to the stage
        layer.add(wheel);
        layer.add(pointer);
        stage.add(layer);
        
        pointerTween = new Kinetic.Tween({
            node: pointer,
            duration: 0.1,
            easing: Kinetic.Easings.EaseInOut,
            y: 30
        });
        
        pointerTween.finish();
        
        var radiusPlus2 = wheelRadius + 2;
        
        layer.draw();


        // Time to start adding the event listeners
        
        function handleMovement() {

            var touchPosition = stage.getPointerPosition(),
                x1 = touchPosition.x - wheel.x(),
                y1 = touchPosition.y - wheel.y();         
        
            if (controlled && target) {

            var x2 = startX - wheel.x(),
                y2 = startY - wheel.y(),
                angle1 = Math.atan(y1 / x1) * 180 / Math.PI,
                angle2 = Math.atan(y2 / x2) * 180 / Math.PI,
                angleDiff = angle2 - angle1;
            
            if ((x1 < 0 && x2 >=0) || (x2 < 0 && x1 >=0)) {
                angleDiff += 180;
            }

            wheel.setRotation(startRotation - angleDiff);

            }
        };


        wheel.on('mousedown touchstart', function(e) {
            //e.evt.preventDefault();
            
            angularVelocity = 0;
            controlled = true;
            target = e.target;
            startRotation = this.rotation();
            
            var touchPosition = stage.getPointerPosition();

            startX = touchPosition.x;
            startY = touchPosition.y;

            // only track the movement if the mouse/finger is down

            document.addEventListener('mousemove', handleMovement );
            document.addEventListener('touchmove', handleMovement );
            
        });
        

        function releaseTheWheel() {
            
            controlled = false;

            if (angularVelocity > maxAngularVelocity) {
                angularVelocity = maxAngularVelocity;
                alert(1);
            } else if (angularVelocity < -1 * maxAngularVelocity) {
                alert(2);
                angularVelocity = -1 * maxAngularVelocity;
            }

            // REMOVE event listeners once the wheel has been released, otherwise console errors

            document.removeEventListener('mousemove', handleMovement );
            document.removeEventListener('touchmove', handleMovement );

        };

        document.addEventListener('mouseup', releaseTheWheel );
        document.addEventListener('touchend', releaseTheWheel );
        

        var anim = new Kinetic.Animation(animate, layer);
        //document.getElementById('debug').appendChild(layer.hitCanvas._canvas);

        anim.start();
    
        }

        init();
        containerEl.className = 'visible';
    } */

    componentDidMount() {
        this.props.onFetchCards()

        i18n.on('languageChanged', this.props.onFetchCards)
    }

    componentWillUnmount() {
        i18n.off('languageChanged', this.props.onFetchCards)
    }

    fetchCards = () => {
        const lng = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').slice(0, 2) : 'en'
        fetch('/api.php', {
            method: 'POST',
            body: JSON.stringify({
                action: 'getCards',
                lang: lng
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Server gave Error:' + response.status);
                } else {
                    return response.json()
                }
            })
            .then((data) => {
                if (data) {
                    if (data.Cards) {
                        // filtering all cards to current language
                        const cardsData = data.Cards.filter(c => c.lang === lng)
                        // console.log('fetched cards in lng OK')

                        const cardsValues = this.formatCards(cardsData)
                        cardsValues.sort((a, b) => a.intensity - b.intensity);

                        // console.log('cards to be passed in state: ', cardsValues)
                        this.setState({ cards: cardsValues })

                    } else {
                        //set error
                    }
                }
            })
            .catch(error => {
                console.error('Products could not be fetched:', error)
                const cards_t = t('cardsInfos', { ns: 'cards', returnObjects: true })
                // this.setProducts(Object.values(pulses_t), 'products')
            })
    }

    formatCards(array) {
        var formatedArray = []

        array.forEach(item => {
            var card = {}

            // determining where star is in text
            const star1 = item.has_starb == 'true' ? true : false
            const star2 = item.has_special_starb == 'true' ? true : false

            card.key = item.image
            card.title = item.en_name
            card.text = [item.en_textb, item.en_text2b]
            card.star1 = star1
            card.star2 = star2
            card.image = item.image
            card.pulse = item.pulse
            card.intensity = item.intensity

            formatedArray.push(card)
        })
        // formatedArray.sort((a, b) => a.title.localeCompare(b.title)); // sorting Cards by title

        return formatedArray
    }

    handler(AltarCard) {
        this.setState({
            fullScreen: !this.state.fullScreen,
            card: AltarCard
        })
        // console.log('AltarCard when clicked: ', this.state.card)
    }

    teset() {
        console.log("click on text")
    }

    render() {
        var cardsContainer = []
        var truth = []
        var lies = []
        var order = []
        var chaos = []
        var life = []
        var death = []
        var pulses = []
        var i = 0

        // gets current language. if undefined, fallback to english
        const lng = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').slice(0, 2) : 'en'

        // fetched Cards
        const { cards } = this.props

        // console.log(cards)

        // cards from local translations
        const cards_t = t('cardsInfos', { ns: 'cards', returnObjects: true })

        // console.log(this.state)

        // either using fetched cards if present or local translations
        var cardsRes = (cards !== undefined && cards.length > 0) ? cards : Object.values(cards_t)

        cardsRes.forEach(cardInfo => {
            var img1 = ''
            try {
                img1 = require('../img/pulses/' + cardInfo.pulse + '/sample/' + lng + '/' + cardInfo.image) // working on lang change
            } catch (error) {
            }

            const card = <AltarCard
                key={cardInfo.image}
                title={cardInfo.title}
                text={cardInfo.text}
                image={img1}
                alt={cardInfo.image}
                pulse={cardInfo.pulse}
                star1={cardInfo.star1}
                star2={cardInfo.star2}
                handler={this.handler}
            />
            // console.log(cardInfo.title)

            cardsContainer.push(
                card
            )

            switch (cardInfo.pulse) {
                case "truth": truth.push(card); break;
                case "lies": lies.push(card); break;
                case "order": order.push(card); break;
                case "chaos": chaos.push(card); break;
                case "life": life.push(card); break;
                case "death": death.push(card); break;
            }
            i = i + 1;
        })


        // console.log(lng, ' - cards shown: ', cardsContainer)

        // Displays cards in each pulse
        const pulses_t = t('pulsesInfos', { ns: 'cards', returnObjects: true })
        if (Object.values(pulses_t)[0]['text'] !== undefined) {

            Object.values(pulses_t).forEach(pulseitem => {

                const img1 = require('../img/pulses/' + pulseitem.image);
                var children = []

                switch (pulseitem.id) {
                    case 1: children = truth; break;
                    case 2: children = lies; break;
                    case 3: children = order; break;
                    case 4: children = chaos; break;
                    case 5: children = life; break;
                    case 6: children = death; break;
                }

                const pulse = (
                    <Pulse
                        key={pulseitem.name}
                        cards={children}
                        name={pulseitem.name}
                        img={img1}
                        text={pulseitem.text}
                        title={pulseitem.title}
                        id={pulseitem.id}
                    />
                );
                pulses.push(pulse)
            })
        }

        var valReturn = []

        valReturn.push(
            <div className='previewPulseContainer py-5'>
                {pulses}
            </div>,
            <div className='wheel'></div>
        )

        // Selected AltarCard on full screen
        if (this.state.fullScreen) {
            // Render without AltarCard effect - Only AltarCard shown
            const isFetched = cards !== undefined && cards.length > 0 ? true : false
            // dynamic text prop wether cards are fetched or local (fetched has text: ['text1', 'text2'] while local has text: 'text')
            const textProp = isFetched ? this.state.card.props.text[0] : this.state.card.props.text
            if (textProp == '') {
                valReturn.push(
                    <Container fluid className='fullScreen'>
                        <div className='fullScreenImage'>
                            {this.state.card.render()}
                        </div>
                    </Container>
                )
                // Render with AltarCard effect - white box with effect translated
            } else {
                valReturn.push(
                    <div className='fullScreen'>
                        <div className='fullScreenImage'>
                            {this.state.card.render()}
                        </div>

                        <div className='imageTextBlock'>
                            <div
                                className='crossExitPopup'
                                onClick={this.state.card.handleShowDialog}
                            >
                                <i className="bi bi-box-arrow-left"></i>
                                {/* <span>Retour</span> */}
                            </div>

                            <div className='imageText'>
                                {this.state.card.props.star1 && (
                                    <div>
                                        <p><i class="bi bi-star"></i></p>
                                    </div>
                                )}
                                <div>
                                    <p>
                                        {isFetched ? this.state.card.props.text[0] : this.state.card.props.text}
                                    </p>
                                </div>
                                {this.state.card.props.star2 && (
                                    <div>
                                        <p><p><i class="bi bi-star"></i></p></p>
                                    </div>
                                )}
                                {this.state.card.props.star2 && isFetched && (
                                    <div>
                                        <p>
                                            {this.state.card.props.text[1]}
                                        </p>
                                    </div>
                                )}

                            </div>

                        </div>
                    </div>
                )
            }
        }
        /*
        $(window).resize(resizeFn()); // bind the resize event handler
        $(document).ready(
            function(){
                $(window).trigger('resize'); // on load, init the lastBoundry
            }
        );
        */

        return valReturn;
    }
}

export default withTranslation('cards')(Cards)