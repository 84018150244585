/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the return button
 *
 * @author Jonathan Buso
 * @since 30.08.2023
 */

import { useNavigate } from 'react-router-dom';

// Translations
import { useTranslation } from 'react-i18next';

import React from 'react'

export default function ReturnButton(props) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <div onClick={() => navigate(-1)} className={`${props.customClass} button gradientPurpleRed buttonRadius buttonShadows`}>{t('return_button')}</div>
    )
}

