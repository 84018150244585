import React from "react";
import { NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  // Available languages. Update when language is added.
  const lngs = {
    en: { nativeName: 'English' },
    fr: { nativeName: 'Français' }
  };

  const changeLanguage = code => {
    if (code !== i18n.language) {
      // changing locale
      i18n.changeLanguage(code);

      // getting  Localized Route
      let url = window.location.pathname;
      
      // rewrite url according to language - optional
      let newLocation = handleLocalizedRoute(url);
    }
  };

  return (
    <NavDropdown title={i18n.language} id="lang-selector">
      {Object.keys(lngs).map((lng) => (
            <NavDropdown.Item key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => changeLanguage(lng)}>
              {lngs[lng].nativeName}
            </NavDropdown.Item>
          ))}
    </NavDropdown>
  );
};

// Function to translate URL according to language - i.e. altarcg.com/checkout <-> altarcg.com/panier
const handleLocalizedRoute = location => {
  // split url pathname by '/'
  let pageArray = location.split("/");
  // parent page as page
  let page = `/${pageArray[1]}`;
  // child item as child if present
  let child = pageArray[2] ? `/${pageArray[2]}` : "";
  console.log(child);
  let newPage = null;
  switch (page) {
    case "/account":
      newPage = "/compte";
      break;
    case "/compte":
      newPage = "/account";
      break;
    case "/checkout":
      newPage = "/panier";
      break;
    case "/panier":
      newPage = "/checkout";
      break;
    default:
      newPage = page;
      return newPage;
  }
  let full = newPage + child;
  return full;
};

export default LanguageSelector;