/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */

import React from 'react';
import Image from 'react-bootstrap/Image'

import { withTranslation } from 'react-i18next';




class AltarCard extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isOpen: false
        }
    }

    handleShowDialog = () => {
        this.state.isOpen = !this.state.isOpen
        this.props.handler(this)
    };


    render() {
        var valReturn = []
        const showDialog = this.props.handler !== undefined ? this.handleShowDialog : null
        if (this.state.isOpen) {
            valReturn.push(
                <Image
                    className="popup"
                    src={this.props.image}
                    onClick={this.handleShowDialog}
                    alt="no image"
                />
            )
        }
        else {
            const pulseClassName = this.props.pulse !== undefined ? ' card_' + this.props.pulse : ''
            valReturn.push(
                <div className='testtttt'>

                    <div className='cardNameRectangle centerText'>
                        <p> {this.props.title} </p>
                    </div>

                    <Image
                        fluid
                        className={"cardImage" + pulseClassName}
                        src={this.props.image}
                        alt={this.props.alt}
                        onClick={showDialog}

                    />
                </div>
            )
        }

        /*
        var valReturn = !this.state.isOpen ? <Image
            fluid
            className="card-image"
            src={this.props.image}
        //onClick={this.handleShowDialog}
        //onClick = {this.props.handler}
        /> : <Image

            className="popup"
            src={this.props.image}
            onClick={this.handleShowDialog}
            alt="no image"
        />
*/

        /*
    
    <dialog
        className="popup"
        /*style={{ position: "absolute" }}
        open
        onClick={this.handleShowDialog}
        >
        */

        //</dialog>

        return valReturn
    }
}
export default withTranslation('cards')(AltarCard);
