import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

import right from '../img/melgraphics/droite_univers.png';

// Translations module
import { t } from 'i18next';


class Tournoi extends React.Component {
    /*
    <Image className='description_right' src={right} alt="" />
    */
    render() {

        return (
            <div className="tournoi_grid">
                <div className='tournoi_container tournoi_image'>

                    <Container className='tournoi_text'>
                        <p className='tournoi_annonce'>{ t('tournoi_annonce') }</p>
                        <p className='tournoi_timer'></p>
                        <p className='tournoi_split1'></p>
                        <p className='tournoi_date'>{ t('tournoi_date') }</p>
                        <p className='tournoi_split2'></p>
                        <p className='tournoi_addr'>{ t('tournoi_addr') }</p>
                    </Container>
                </div>
            </div >
        )
    }

}

export default Tournoi

