/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */


import React from 'react';

// Translations Module
import { t } from 'i18next';

class Footer extends React.Component{
    render(){
        return <section className="footer pt-5">
            <div className="container text-center text-md-start">
                <div className="row mt-3">
                    <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                        <h6 className="text-uppercase fw-bold">Abalance</h6>
                        <hr
                                className="mb-4 mt-0 d-inline-block mx-auto"
                                style={{"width": "60px", "height": "2px"}}
                        />
                        <p>
                            { t('footer_description') }
                        </p>
                    
                    </div>
                    <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                        <h6 className="text-uppercase fw-bold">{ t('footer_useful_links') }</h6>
                        <hr
                                className="mb-4 mt-0 d-inline-block mx-auto"
                                style={{"width": "60px", "height": "2px"}}
                        />
                        <p>
                            <a href="https://www.abalance.ch/" target="_blank"> Abalance </a>
                        </p>
                        <p>
                            <a href="mailto::contact@abalance.ch?subject=Investissement&body=Renseignement concernant l'investissement" target="_blank" >{ t('footer_donation') }</a>
                        </p>

                        <p>
                            <a href = "https://docs.google.com/document/d/1-51AabhZIUlR3iiFk3uXHgssK25Ir0grRBvwERM8ZV4/edit" target="_blank">{ t('footer_altar_regles') }</a>
                        </p>


                        <p>
                        <a href = "https://forms.gle/7WWgMZ8qoar13zk37" target="_blank">{ t('footer_stats') }</a>
                                
                        </p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                        <h6 className="text-uppercase fw-bold">{ t('footer_contact_title') }</h6>
                        <hr
                                className="mb-4 mt-0 d-inline-block mx-auto"
                                style={{"width": "60px", "height": "2px"}}
                        />
                        <p><i className="fas fa-home mr-3"></i> Rue de la Balance 1, 2000 Neuchâtel</p>
                        <p><i className="fas fa-envelope mr-3"></i>  <a href="mailto::contact@abalance.ch">contact@abalance.ch</a></p>
                    </div>
                </div>
            </div>
           
            <div
                className="text-center p-3"
                style={{"backgroundColor": "rgba(0, 0, 0, 0.2)"}}
            >
                © Copyright: { t('footer_copyright') }
            </div>
        </section>
    }
}



export default Footer
