
import React, { Component }  from 'react';

import {loadStripe} from '@stripe/stripe-js';

import Checkout from "../components/checkout";
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';



// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
    'pk_test_51GuzXTDsUJ335a3Xr7GhQvX9PXqUdjyMS1gAlDtflBOJ5CHsT5PzHCXNCW7cva2V5ygpvJ55w3eaPQxZuQi5MPmQ00nEvXYYnD'
);

const items = [{id:"all_pulses" }]
    
export default function checkout() {
    const { t } = useTranslation()
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('page_checkout')} - Altar</title>
            </Helmet>
            <Checkout items = {{items}} > </Checkout>
        </>
    );
};