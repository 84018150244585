import React, { Component } from "react";
import { Modal, Card, Badge, Button } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import Slider from "react-slick";

import { t } from 'i18next'

export default class ResponsiveSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShown: false,
    };
  }

  handleVisibility = (value) => {
    this.setState({ isShown: value });
  }
    render() {

        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }

        const { items, onAddToCart } = this.props
        const { isShown } = this.state
        const lng = localStorage.getItem('i18nextLng') !== undefined ? localStorage.getItem('i18nextLng').slice(0, 2) : 'en'

        return (
            <Slider {...settings}>
                {items.map(exp => {
                    return (
                        <>
                            <Card key={exp.id} className='buy_expansion_cards col-md-4 d-flex' style={{ "max-width": "18rem" }}>
                                <div className="position-relative">
                                    <Card.Img variant="top" src={require('../img/pulses/' + exp.image)} alt={exp.image} onError={(e) => { e.target.src = require('../img/icons/sample.png') }} />
                                    <Badge className='buy_expansion_cards_badge' bg="danger">{t('buy_new_badge')}</Badge>
                                </div>
                                <Card.Body>
                                    <Card.Title>{exp.title}</Card.Title>
                                    <Card.Text className='buy_expansion_cards_text'>
                                        {exp.description}
                                    </Card.Text>
                                </Card.Body>
                                <Card.Body>
                                    <button className='mb-2 buy_expansion_modal_link' onClick={() => this.handleVisibility(true)} >{t('buy_see_more')}</button>
                                    <div className='d-inline float-end'><span className='buy_expansion_cards_base_price'>CHF {exp.cost}</span><span className="buy_expansion_cards_discounted_price">CHF {exp.cost}</span></div>
                                </Card.Body>
                                <Card.Body>
                                    <div className='button gradientOrange buttonRadius p-2' onClick={() => onAddToCart(exp.id)}>{t('buy_add_to_cart')}</div>
                                </Card.Body>
                                <Card.Footer>
                                    <small className="text-muted">{t('buy_release_text') + ' ' + exp.startdate}</small>
                                </Card.Footer>
                            </Card>
                            <Modal
                                show={isShown}
                                onHide={() => this.handleVisibility(false)}
                                fullscreen="md-down"
                                size="xl"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                dialogClassName='buy_modal_expansion'
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        {t('buy_modal_expansion')}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className='border-bottom'>
                                    <Row className='modal_expansion_info'>
                                        <Col xs={6} md={4} className='mx-auto'>
                                            <img src={require('../img/pulses/' + exp.image)} alt={exp.image} onError={(e) => { e.target.src = require('../img/icons/sampleimg.png') }} />
                                        </Col>
                                        <Col xs={12} md={8}>
                                            <h2>{exp.title}</h2>
                                            <p>{exp.description}</p>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                {exp.cards !== undefined && (
                                    <Modal.Body className='modal_expansion_cards'>
                                        <Row className='justify-content-center'>
                                            {exp.cards.map((card) => (
                                                <Col xs={12} md={6} ld={4} xl={2}>
                                                    <Card image={require('../img/pulses/' + card.image)} />
                                                </Col>
                                            ))}
                                        </Row>
                                    </Modal.Body>
                                )}
                                <Modal.Footer>
                                    <div className='button gradientOrange buttonRadius buy_modal_add_to_cart_button' onClick={() => {
                                        onAddToCart(exp.id)
                                        this.handleVisibility(false)
                                    }}
                                    >
                                        {t('buy_add_to_cart')}
                                    </div>
                                    <Button variant="danger" onClick={() => this.handleVisibility(false)}>{t('modal_close')}</Button>
                                </Modal.Footer>
                            </Modal>
                        </>

                    )
                })
                }
            </Slider>
        );
    }
}